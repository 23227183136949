.weather-content{
    background:rgba(16, 0, 27, 0.9);
    padding:25px 20px;
    max-width:600px;
    width:100%;
    border-radius:25px;
    border: 12px double #fff;
    z-index:2;
}
.top-feed, .top-feed div{
    display: flex;
}
.top-feed div:nth-of-type(2){
    align-items: center;
}
.top-feed{
    justify-content: space-between;
    align-items: center;
}
.top-feed h1{
    margin:-6px 0 0 10px;
    font-family: 'IBM Plex Mono', monospace;
    font-weight: 600;
    font-size:30px;
    text-transform: uppercase;
}
.top-feed div:nth-of-type(2) p{
    font-family: 'IBM Plex Mono', monospace;
    font-weight: 600;
    font-size:16px;
    text-align: right;
}
.main-feed{
    display: flex;
    align-items: center;
    flex-basis: 100%;
    flex-wrap:wrap;
    text-align: left;
    margin-top: -30px;
}
.main-feed p{
    font-family: 'Bungee Shade', cursive;
    flex-basis: 100%;
    font-size:170px;
    display: block;
    flex-basis: 100%;
    
}
.bottom-feed{
    text-align: left;
    display: flex;
    justify-content: space-between;
}
.bottom-feed p{
    font-family: 'IBM Plex Mono', monospace;
    font-weight: 600;
    font-size:26px;
}
.future-weather{
    background:rgba(16, 0, 27, 0.9);
    padding:25px 20px;
    max-width:600px;
    width:100%;
    border-radius:25px;
    border: 12px double #fff;
    z-index:2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 20px;
    margin-top:10px;
}
.future-weather div{
    flex-basis: 25%;
    text-align: center;
}
.future-weather h2{
    flex-basis: 100%;
    text-align: center;
    margin:0;
    font-size: 20px;
    font-family: 'IBM Plex Mono', monospace;
    font-weight: 600;
    text-transform: uppercase;
}
.future-weather div span:first-of-type{
    font-style: italic;
    font-size:16px; 
    text-decoration: underline;
}
.future-weather div span:nth-of-type(2){
    display: block;
    font-size:32px;
    font-style: normal;
    text-decoration: none;
}
footer{
    flex-basis: 100%;
    bottom: 10px;
    position: absolute;
    text-align: center;
    z-index: 1;
    left: 0;
    right: 0;
}
footer div{
    max-width:1200px;
    margin:0 auto;
    
}
footer div p{
    text-align: center;
}
@media(max-width:767px){
    .main-feed p{
        font-size:120px;
        text-align: center;
    }
    .weather-content, .future-weather{
        max-width:400px;
        width: 80%;
        border: none;
    }
    .bottom-feed p{
        font-size:18px;
    }
    .top-feed h1{
        font-size: 24px;
        margin-top:-4px;
    }
    .future-weather{
        margin-top:0;
    }
    .future-weather p span{
        font-size:24px;
    }
    .future-weather div span:nth-of-type(2){
        font-size:26px;
    }
}
@media(max-width:600px){
    .main-feed p{
        font-size:100px;
    }
    .top-feed div:nth-of-type(2) p{
        font-size:14px;
    }
    .bottom-feed p{
        font-size:16px;
    }
    .future-weather div span:nth-of-type(2){
        font-size: 24px;
    }
    .top-feed h1{
        font-size: 20px;
        margin-top: 0;
    }
    
}
@media(max-width:400px){
    .top-feed div:nth-of-type(2) p{
        display: none;
    }
    .future-weather div span:nth-of-type(2){
        font-size: 22px;
    }
    .bottom-feed p{
        font-size: 15px;
    }
    .main-feed p{
        font-size:90px;
        margin: 20px 0;
    }
    .future-weather{
        margin-top:-60px;
    }
}   
@media(max-width:340px){
    .main-feed p{
        font-size:70px;
    }
}   
/* Form */
.weather-content form{
    text-align: center;
}