@import url('https://fonts.googleapis.com/css2?family=Bungee+Shade&family=IBM+Plex+Mono&display=swap');
body{
  overflow-x: hidden;
  background-color: #111;
}
.weather-app {
  background: url("../public/images/stacked-waves-haikei-purp.png");
  background-color: #111;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: -webkit-fill-available;
  min-height: 100vh;
  display: grid;
  place-items: center;
  color:#fff;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
button{
  background: #f1356d;
  color: #fff;
  border: 0;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  font-family: 'IBM Plex Mono', monospace;
}
input{
  padding: 8px;
  border-radius: 8px;
  margin-right:8px;
}
p{
  margin: 0;
  font-family: 'IBM Plex Mono', monospace;
  font-size: 18px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media(max-width: 1024px) {
  .weather-app {
    background-position: right;
  }
}